// 编辑班级的弹框
<template>
  <div class="edit_teacher">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="real_content">
          <!-- 加载框 -->
          <Loading v-if="showLoading"></Loading>
          <!-- 内容 -->
          <div class="edit_teacher_dialog_content" v-else>
            <!-- 列表布局 -->
            <div class="course_list">
              <!-- 学校名称 -->
              <div class="margin_top_30">
                <div>
                  <span class="red_icon">*</span>
                  <span>班级名称 ：</span>
                  <span>
                    <el-input
                      class="name_input"
                      clearable
                      v-model="className"
                      placeholder="请输入班级名称"
                      maxlength="20"
                      size="small"
                    ></el-input
                  ></span>
                </div>
              </div>
    <!-- 选择老师 -->
              <div class="margin_top_10">
                <div>
                  <span class="red_icon">*</span>
                  <span>班级类型 ： </span>
                  <span class="name_input">
                    {{ classType }}
                  </span>
                </div>
              </div>
              <!-- 课程访问权限 -->
              <div class="margin_top_20">
                <div>
                  <span class="red_icon">*</span>
                  <span>班级课程 ：      <span      v-if="CourseList == null||CourseList.length==0" style="line-height: 20px">暂无课程权限</span></span>
                   <div class="select_list">
               
                    <div
                    v-if="CourseList!=null"
                      class="item"
                      v-for="(item, index) in CourseList"
                      :key="index"
                    >
                      <span
                        :class="{
                          selected: CourseList[index].state == 1,
                          unselected: CourseList[index].state == 0,
                          noneselect: CourseList[index].state == -1,
                        }"
                        @click.stop="selectCourse(index)"
                      >
                        {{ item.subName }}
                      </span>

                      <span
                        v-for="(item, k) in CourseList[index].levelList"
                        :key="k"
                      >
                        <span
                          :class="{
                            item_selected:
                              CourseList[index].levelList[k].state == 1,
                            item_unselected:
                              CourseList[index].levelList[k].state == 0,
                            item_noneselect:
                              CourseList[index].levelList[k].state == -1,
                          }"
                          @click="selectCourseItem(index, k)"
                          >{{ item.levelName }}</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 选择老师 -->
              <div>
                <div class="margin_top_20">
                  <span class="red_icon">*</span>
                  <span>选择老师 ： </span>
                  <span class="name_input">
                    {{ teacherName }}
                  </span>
                </div>
              </div>
              <!-- 预招生人数 -->
              <div class="margin_top_20">
                <div>
                  <span>预招生人数：</span>
                  <span>
                    <el-input
                      class="name_input"
                      clearable
                      v-model.number="classNumber"
                      placeholder="必须为整数"
                      maxlength="20"
                      size="small"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                    ></el-input
                  ></span>
                </div>
              </div>

              <!-- 是否限制人数 -->
              <div class="margin_top_20">
                <div>
                  <span>限制人数：</span>
                  <span class="margin_left_30">
                    <el-switch v-model="isLimPeople"> </el-switch>
                  </span>
                  <span class="margin_left_10"
                    >开启后入班人数不可超过预招人数</span
                  >
                </div>
              </div>
            </div>
            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 取消 -->
              <div class="cancel" @click.stop="close">
                <span>取</span><span style="margin-left: 16px">消</span>
              </div>
              <!-- 确定 -->
              <el-button
                :loading="showCommitLoading"
                :class="['commit']"
                @click.stop="doCommitAction"
              >
                <span>确</span><span style="margin-left: 16px">定</span>
              </el-button>
            </div>

            <!-- 确定取消框 -->
            <!-- <div class="inner_dialog"></div> -->
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "../../components/CommonDialog";
export default {
  data() {
    return {
      //宽度
      width: "680px",
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
      //显示加载框
      showLoading: false,
      courseData: [],
      //班级名称
      className: "",
      //手机号
      phoneNumber: "",
      //校区名或编码
      schoolName: "",
      CourseList: [],

      //教师姓名
      teacherName: "",
      //班级类型
      classType:"正式班",
      //预招生人数
      classNumber: "",
      //限制人数
      isLimPeople: false,
      //班级人数
      isClassChange: 0,
    };
  },
  created() {},
  mounted() {
    this.getClassData();
  },
  methods: {
    //获取班级信息
    getClassData() {
      let param = this.getHttpParams();
      param.type = 5;
      param.cid = this.classItem.class_id;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            console.log(resulte);
            //课程列表
            this.CourseList = resulte.data.authority;
            //老师姓名
            this.teacherName = resulte.data.teacher_name;
            //班级名称
            this.className = resulte.data.class_name;
            //预招生人数
            this.classNumber = resulte.data.plan_num;
            //班级人数
            this.isClassChange = resulte.data.class_num;

            
             if (resulte.data.type == 0) {
              this.classType = "试听班";
            } else {
              this.classType = "正式班";
            }
            //是否限制
            if (resulte.data.limit_plan == 1) {
              this.isLimPeople = true;
            } else {
              this.isLimPeople = false;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
    //确定的操作
    doCommitAction() {
      this.showCommitLoading = true;
      this.httpUpload();
    },
    //上传接口
    httpUpload() {
      if (this.isEmpty(this.className)) {
        this.showWarnMsg("班级名称不能为空");
        this.showCommitLoading = false;
        return;
      }

      if (this.isLimPeople && this.isEmpty(this.classNumber)) {
        this.showWarnMsg("请输入预招生人数");

        this.showCommitLoading = false;
        return;
      }

      if (this.classNumber < this.classItem.class_num) {
       
        this.showWarnMsg("该班级实际人数超过了预招生人数,无法保存成功");
        this.showCommitLoading = false;
        return;
      }

     

      this.levelInfo = JSON.stringify(this.CourseList);

      let param = this.getHttpParams();
      param.type = 6;

      param.cid = this.classItem.class_id;
      param.className = this.className;
      param.levelInfo = this.levelInfo;
      param.planNum = this.classNumber;
      if (this.isLimPeople) {
        param.limitPlan = "1";
      } else {
        param.limitPlan = "0";
      }

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            this.showSuccessMsg("编辑成功");
            //触发父组件的关闭方法
            this.$emit("close");
             this.$emit("reflash");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
            this.showCommitLoading = false;
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
          this.showCommitLoading = false;
        });
    },

       //选中科目 状态（-1余额不足，0未开启，1存在已开启级别）
    selectCourse(index) {
      if(this.isClassChange!=0){
        return;
      }

      // console.log("选中科目" + index);
      if (this.CourseList[index].state == 0) {
        this.CourseList[index].state = 1;

        // this.CourseList[index].levelList.forEach((element) => {
        //   element.state = 1;
        // });
        this.CourseList[index].levelList[0].state = 1;
      } else if (this.CourseList[index].state == 1) {
        var temp = false;
        this.CourseList[index].levelList.forEach((element) => {
          if (element.state == 0) {
            element.state = 1;
            temp = true;
          }
        });
        if (!temp) {
          this.CourseList[index].state = 0;
          this.CourseList[index].levelList.forEach((element) => {
            element.state = 0;
          });

          if(this.CourseList[index].planState == 1){
          this.showWarnMsg("本班级该课程级别已有排课记录，修改权限后，该班级的考勤与评价数据会缺失，请谨慎操作！");
        }
        }

       
     
      }
  
    },
    //选中级别 状态（-1余额不足，0未开启，1存在已开启级别）
    selectCourseItem(index, k) {
      event.stopPropagation();
       if(this.isClassChange!=0){
        return;
      }
      // console.log("选中级别" + index + "k=" + k);
      if (this.CourseList[index].levelList[k].state == 0) {
        this.CourseList[index].levelList[k].state = 1;
        this.CourseList[index].state = 1;
      } else if (this.CourseList[index].levelList[k].state == 1) {
        this.CourseList[index].levelList[k].state = 0;
       
        
        if(this.CourseList[index].levelList[k].planState == 1){
          this.showWarnMsg("本班级该课程级别已有排课记录，修改权限后，该班级的考勤与评价数据会缺失，请谨慎操作！");
        }
        var isSelect = true;
        this.CourseList[index].levelList.forEach((element) => {
          if (element.state == 1) {
            isSelect = false;
          }
        });

        if (isSelect) {
          this.CourseList[index].state = 0;

        }
      }

      
    },

  },
  watch: {
    //监听输入框值的变化
    classNumber(newValue, oldValue) {
      if (newValue > 50) {
        this.classNumber = 50;
        this.showWarnMsg("预招生人数最大不能超过50人");
      }

       
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title", "classItem"],
};
</script>
<style lang="scss" scoped>
.edit_teacher {
  ::v-deep .el-dialog {
    top: 50%;
    // background: #eff2f7;
  }

  .real_content {
    .edit_teacher_dialog_content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .course_list {
        margin-left: 5%;
        min-height: 300px;

        .name_input {
          margin-left: 1%;
          width: 40%;
        }
        .input {
          width: 40%;
        }

        .tips {
          margin-left: 20%;
          color: #ff0000;
        }
        .course_line {
          margin-top: -30px;
        }

        .select_list {
          margin-left: 13.5%;
          margin-top: -16px;
               padding-top: 10px;
          overflow-y: scroll;
          max-height: 45vh;
        }
       .item {
          min-height: 45px;
          width: 100%;
        }
        .item_.noneselect {
          background-color: #cccccc;
          padding: 5px;
          border-radius: 5px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .noneselect {
          background-color: #cccccc;
          padding: 8px;
          border-radius: 10px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;

          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .selected {
          background-color: #f39800;
          border-radius: 10px;
          width: 120px;
          padding: 10px;
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .unselected {
          background-color: #ffffff;
          padding: 8px;
          border-radius: 10px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .unselected:hover {
          background-color: #ffffff;
          border-radius: 10px;
          width: 120px;
          padding: 8px;
          color: #f39800;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }

        .item_selected {
          background-color: #f39800;
          border-radius: 5px;
          width: 8px;
          padding: 5px;
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .item_unselected {
          background-color: #ffffff;
          padding: 5px;
          border-radius: 5px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .item_unselected:hover {
          background-color: #ffffff;
          border-radius: 5px;
          width: 120px;
          padding: 5px;
          color: #f39800;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
      }

      //底部的操作按钮
      .bom_action {
        margin-top: 20px;
        height: 48px;
        display: flex;
        justify-content: center;

        .cancel,
        .commit {
          width: 120px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }

        .cancel {
          background: #dcdcdc;
          margin-right: 30px;
          color: black;
        }

        .commit {
          background: #169bd5;
          // background: #70B600;
          color: white;
        }
      }

      //操作框
      .inner_dialog {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.4);
      }
    }
  }
}
</style>